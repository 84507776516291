<template>
  <div>
		<!-- <img src="../assets/img/ref.png" alt="" class="manke-ref" ref="mankeRef" @touchstart="touchStart('mankeRef', $event)" @touchmove="touchMove('mankeRef', $event)" @touchend="touchEnd()"> -->
		<!-- <img src="../assets/img/ref.png" alt="" class="manke-ref" ref="mankeRef" @touchstart.prevent="touchStart('mankeRef', $event)" @touchmove.prevent="touchMove('mankeRef', $event)" @touchend.prevent="touchEnd()" @click.prevent="toRef"> -->
		<img src="../assets/img/ref.png" alt="" class="manke-ref" @click="toRef">
		<van-popup class="ref-popup" v-model="show"></van-popup>
	</div>
</template>

<script>
import Vue from "vue"
import { Popup } from 'vant'
Vue.use(Popup)
export default {
  name: "",
  data() {
    return {
			location: {
				client: {},
				position: {}
			},
			show: false
		};
  },
  props: {
    info: {} // 接收参数
  },
  components: {},
  created() {
	},
  mounted() {},
  methods: {
		/**
		 * 拖拽开始
		 */
		touchStart(refName, e) {
			this.show = true
			let element = e.targetTouches[0]
			// 记录点击的坐标
			this.location.client = {
				x: element.clientX,
				y: element.clientY
			}
			// 记录需要移动的元素坐标
			this.location.position.left = this.$refs[refName].offsetLeft
			this.location.position.top = this.$refs[refName].offsetTop
		},

		/**
		 * 拖拽中
		 */
		touchMove(refName, e) {
			let innerWidth = window.innerWidth
			let innerHeight = window.innerHeight
			let element = e.targetTouches[0]
			// 根据初始 client 位置计算移动距离(元素移动位置=元素初始位置+光标移动后的位置-光标点击时的初始位置)
			let x = this.location.position.left + (element.clientX - this.location.client.x)
			let y = this.location.position.top + (element.clientY - this.location.client.y)
			// 限制可移动距离，不超出可视区域
			x = x <= 0 ? 0 : x >= innerWidth - this.$refs[refName].offsetWidth ? innerWidth - this.$refs[refName].offsetWidth : x
			y = y <= 0 ? 0 : y >= innerHeight - this.$refs[refName].offsetHeight ? innerHeight - this.$refs[refName].offsetHeight : y
			// 移动当前元素
			this.$refs[refName].style.left = x + 'px'
			this.$refs[refName].style.top = y + 'px'
		},

		/**
		 * 拖拽结束
		 */
		touchEnd () {
			this.show = false
		},

		/**
		 * 跳转参考文献
		 */
		toRef () {
			// console.log(this.info)
			localStorage.setItem('mankeRef', this.info)
			this.$router.push('/reference')
		}
	}
};
</script>
<style lang="scss" scoped>
.manke-ref{
	width: 75px;
	position: fixed;
	bottom: 5%;
	left: 20px;
	z-index: 100;
	pointer-events: initial;
}
.ref-popup{
	background: transparent;
	z-index: 99!important;
}
/deep/ .van-overlay{
	background: transparent;
	z-index: 99!important;
}
</style>