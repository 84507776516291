<template>
  <div class="wrap" ref="wrap">
		<div class="content">
			<!-- 人体视图 -->
			<div class="body" v-if="tabIdx == 0">
				<div class="title">
					<svg xmlns="http://www.w3.org/2000/svg" width="154.64" height="34.12" viewBox="0 0 154.64 34.12" @click="onList()">
						<path id="路径_1545" data-name="路径 1545" d="M28.56-.92l4.12-1.24-.96-11.2-4.6.16.16,3.4-.32,1-.2.32L31.12-5,28.76-2.04,24.84-5.16,21.96-.24,18.72-2.2l4.76-8-.12-2.88-5,.12V-.6h-3.8V-14.8l1.84-1.92,6.8-.16-.08-2.24-7.96.2-.08-3.72,19.72-.6.12,3.8-8.08.24.08,2.2,6.44-.16,1.96,1.72L36.56-.96,35.28,1l-5.6,1.68Zm-26.4-9.6,6.2-3.44.24-3.36-4.32-4,2.52-2.8,2.12,1.96.28-4.2,1.88-1.76,12.16-.28-.64-1.84,3.56-1.2,1,2.92,10.6-.24.12,3.8-25,.6-1.76,26L7.36,1.4,8.08-9.48,4-7.2ZM47.08.72l-1.8-1.84-.64-25.56,1.76-1.88,26.96-1.96,2.04,1.88L74.64-.04l-2,1.8Zm23.8-2.8.68-24.56L48.44-24.96l.56,22ZM50-18.88l7.76-.8,3.2-5.44,3.24,1.88-1.8,3.08,7.44-.76.4,3.76-10.2,1.04L53.36-4.64,50.04-6.52l5.32-9.12-4.96.52Zm10.16,7.16,2.68-2.64,5.92,6.04L66.04-5.68ZM87.84,2.68,85.08.64l3.76-17.76-6-1.88L84-22.6l7.6,2.4L92.88-18,89.64-2.52l3.64-1.92,1.8,3.28Zm19.32-30.16,10,7.56-2.28,3L104.4-24.8l-8.8,8.36L93-19.2l12.96-12.24,2.52,2.72ZM97.36,2.64,95.56-.64l18.8-10.32,1.8,3.32Zm.6-7.84L96.2-8.56,110.44-16l1.76,3.36Zm.48-8-1.36-3.52,10.8-4.24,1.4,3.52ZM91.6-22.72l-7.12-4.92,2.16-3.12,7.12,4.96Zm44.76,6.56,4.16,6L137.4-8l-2.24-3.24v6.48l4.16-.8L140-1.84,125.44,1,123.2-.84V-28.96h3.76v4.4l2.8-1.68,1.6,2.68v-6h3.8v5.96l3-4.56,3.16,2.04-4,6.08,1.64-.2.44,3.72Zm16.88-.64.48,18.28-3.76.12-.48-18.2-3.36.2-.08,11.24-.12.76L143,2.28,139.56.8l2.76-6.36.12-21.24,1.48-1.8,10.64-2.52.88,3.68-9.24,2.12-.04,5.16,10.44-.68.2,3.8ZM128-19l1.6-.16-2.64-4.4V-8.24l3.48-7.24-2,.24ZM126.96-5.76v2.6l6.6-1.28h-2.2V-8.68L129.4-4.6Z" transform="translate(-2.16 31.44)" fill="#e6f37b"/>
					</svg>
					<img v-if="listUp" src="../assets/img/diacrisis/up.png" alt="">
					<img v-else src="../assets/img/diacrisis/down.png" alt="">
				</div>
				<div class="tip" v-if="!spotState">
					<div>
						<div class="tip-1"><span></span>常见病因</div>
						<div class="tip-2"><span></span>次常见病因</div>
						<div class="tip-3"><span></span>少见病因</div>
					</div>
				</div>
				<img class="bg" src="../assets/img/home/body.png" alt="" />
				<ul class="list" :class="listUp ? 'on' : ''">
					<li :class="bodyOn == index && item.on == 1 ? 'on' : item.on == 2 ? 'off' : ''" v-for="(item, index) in list" :key="index">
						<div @click="onSelect(index)">
							<span class="name">{{item.title}}</span>
						</div>
					</li>
				</ul>
				<ul class="sub-list">
					<li v-for="(item, index) in list" :key="index" :class="'list_' + index" :style="{top: (item.gridy / 902 * 100) + '%', left: (item.gridx / 499 * 100) + '%'}">
						<!-- <img class="sub-list-spot" src="../assets/img/diacrisis/spot-yellow.png" alt="" :style="{opacity: spotState ? '1' : '0'}" /> -->
						<div class="sub-list-wrap" :class="bodyOn == index && item.on ? 'on' : ''">
							<div class="sub-list-name">{{item.title}}</div>
							<ul>
								<li v-for="(item1, index1) in item.children" :class="'li_' + item1.type" :key="index1" @click="toDetail(1, item1.detail_id, item1.id)">
									<div :class="item1.detail_id ? 'on' : ''">{{item1.title}}</div>
									<!-- <img v-if="item1.detail_id" src="../assets/img/next.png" alt=""> -->
								</li>
							</ul>
						</div>
					</li>
				</ul>
			</div>

			<!-- 列表视图 -->
			<div class="view" v-else>
				<div class="tip">
					<div>
						<div class="tip-1"><span></span>常见病因</div>
						<div class="tip-2"><span></span>次常见病因</div>
						<div class="tip-3"><span></span>少见病因</div>
					</div>
				</div>
				<img class="bg" src="../assets/img/home/body.png" alt="" />
				<div class="view-wrap">
					<div class="left">
						<div :class="iconOn == index ? 'on' : ''" v-for="(item, index) in list" :key="index" @click="onChange(index)">
							<span class="sprite" :class="iconOn == index ? item.sign + '_1' : item.sign"></span>
						</div>
					</div>
					<div class="right">
						<div class="name">{{list[iconOn].title}}</div>
						<ul>
							<li @click="toDetail(2, item.detail_id, item.id)" :class="'li_' + item.type" v-for="(item, index) in list[iconOn].children" :key="index">
								<div>{{item.title}}</div>
								<img v-if="item.detail_id" src="../assets/img/next.png" alt="">
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="tab">
			<div class="tab-list" :class="tabIdx == index ? 'on' : ''" v-for="(item, index) in tab" :key="index" @click="tabChange(index)">
				<img :src="tabIdx == index ? item.imgOn : item.img" alt="">
				<div v-html="tabIdx == index ? item.nameOn : item.name"></div>
			</div>
		</div>

		<!-- 量表 -->
		<img class="gauge" src="../assets/img/gauge.png" alt="" ref="gaugeBox" @touchstart="touchStart('gaugeBox', $event)" @touchmove="touchMove('gaugeBox', $event)" @click="toPath()">
		
		<!-- 参考文献 -->
		<Reference :info="refInfo"></Reference>
		<div class="copyright"><a href="https://beian.miit.gov.cn/#/home">沪ICP备13026779号-18&nbsp;&nbsp;&nbsp;&nbsp;</a><img src="../assets/img/gongAn.png">&nbsp;<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402009903">沪公网安备31010402009903号</a></div>
	</div>
</template>

<script>
import { Toast } from 'vant'
import { getDiagnosis } from '../common/api/index'
import Reference from '../components/Reference'
export default {
  data() {
    return {
			tabIdx: 0,
			tab: [
				{
					img: require('../assets/img/diacrisis/body.png'),
					imgOn: require('../assets/img/diacrisis/body_1.png'),
					name: '<svg xmlns="http://www.w3.org/2000/svg" width="152.88" height="34.88" viewBox="0 0 152.88 34.88"><path id="路径_1544" data-name="路径 1544" d="M18.6-14.24l2.6-2.72L36.36-2.72,33.76.04ZM3.64-.36,21.6-30.32l3.2,1.96L6.84,1.56Zm63.64-21.2-.6,11.92,2.44-.4.6,3.72-3.24.56L66.04,2.6l-3.8-.2.4-7.52-3.84.64L58.12-8.2,62.84-9l.64-12.12-2,.24L54.8.72,51.2-.44l6.16-19.92-5.48.64-.44-3.76,12.24-1.48.36-6.36,3.76.12-.32,5.8,8-1,.44,3.76Zm-17.8-2.6.6,26.56-3.76.12-.56-22.96L44-18.64l-2.72-2.64L49.92-30l2.72,2.68ZM75.4-1.52,67.44-17.48,70.8-19.2l7.92,16Zm41.12-9.96-3.76-.32,1.2-14.56L100.8-23.88l.92,13.48-3.8.2L96.68-29.6l3.72-.24.12,2.2,15.16-2.84,2.24,2ZM92.08-14.32,97-11,94.92-7.88l-3-2L91.8,1.84,88,1.8l.12-10.48L84.68-3.8,81.56-6l6.64-9.32v-.12h.08L91-19.24l-8-.52.2-3.72,11.4.68L96-19.84Zm8.2,16.96L96.8,1.16l10.6-24.6,3.48,1.48Zm9.6-1.32L107.72-.4l-.44-8.8L111-9.4l.36,6.72,6.44-1.08.6,3.72ZM89.92-23.96l-4.36-5L88.4-31.4l4.36,4.96Zm61.88,27-25.16-.6L124.8.64l-1.32-29.6,1.8-2,29.36-.88,1.88,2.08L153.68,1.32ZM128.48-1.24l16.64.36L131.84-3.36l.68-3.72,13.88,2.6-.72,3.6,4.4.08L152.6-28l-16.08.52,1.52,1.32-1.2,1.36,12.84,3.04.36,3.56-6.32,2.84,6.56,2.72L148.84-9.2l-9.8-4.04L135.4-11.6l10.72,2.12-.68,3.68L134.48-7.96l.72-3.56-5.68,2.56L128-12.4l6.24-2.8-1.52-.64,1.4-3.52,4.84,2.04,4.48-2.04-9.4-2.28v-.08l-3,3.36-2.8-2.48,5.88-6.56-6.8.2Z" transform="translate(-3.64 31.84)" fill="#fff"/></svg>',
					nameOn: '<svg xmlns="http://www.w3.org/2000/svg" width="152.88" height="34.88" viewBox="0 0 152.88 34.88"><path id="路径_1543" data-name="路径 1543" d="M18.6-14.24l2.6-2.72L36.36-2.72,33.76.04ZM3.64-.36,21.6-30.32l3.2,1.96L6.84,1.56Zm63.64-21.2-.6,11.92,2.44-.4.6,3.72-3.24.56L66.04,2.6l-3.8-.2.4-7.52-3.84.64L58.12-8.2,62.84-9l.64-12.12-2,.24L54.8.72,51.2-.44l6.16-19.92-5.48.64-.44-3.76,12.24-1.48.36-6.36,3.76.12-.32,5.8,8-1,.44,3.76Zm-17.8-2.6.6,26.56-3.76.12-.56-22.96L44-18.64l-2.72-2.64L49.92-30l2.72,2.68ZM75.4-1.52,67.44-17.48,70.8-19.2l7.92,16Zm41.12-9.96-3.76-.32,1.2-14.56L100.8-23.88l.92,13.48-3.8.2L96.68-29.6l3.72-.24.12,2.2,15.16-2.84,2.24,2ZM92.08-14.32,97-11,94.92-7.88l-3-2L91.8,1.84,88,1.8l.12-10.48L84.68-3.8,81.56-6l6.64-9.32v-.12h.08L91-19.24l-8-.52.2-3.72,11.4.68L96-19.84Zm8.2,16.96L96.8,1.16l10.6-24.6,3.48,1.48Zm9.6-1.32L107.72-.4l-.44-8.8L111-9.4l.36,6.72,6.44-1.08.6,3.72ZM89.92-23.96l-4.36-5L88.4-31.4l4.36,4.96Zm61.88,27-25.16-.6L124.8.64l-1.32-29.6,1.8-2,29.36-.88,1.88,2.08L153.68,1.32ZM128.48-1.24l16.64.36L131.84-3.36l.68-3.72,13.88,2.6-.72,3.6,4.4.08L152.6-28l-16.08.52,1.52,1.32-1.2,1.36,12.84,3.04.36,3.56-6.32,2.84,6.56,2.72L148.84-9.2l-9.8-4.04L135.4-11.6l10.72,2.12-.68,3.68L134.48-7.96l.72-3.56-5.68,2.56L128-12.4l6.24-2.8-1.52-.64,1.4-3.52,4.84,2.04,4.48-2.04-9.4-2.28v-.08l-3,3.36-2.8-2.48,5.88-6.56-6.8.2Z" transform="translate(-3.64 31.84)" fill="#49fcfc"/></svg>'
				},
				{
					img: require('../assets/img/diacrisis/list.png'),
					imgOn: require('../assets/img/diacrisis/list_1.png'),
					name: '<svg xmlns="http://www.w3.org/2000/svg" width="153" height="34.88" viewBox="0 0 153 34.88"><path id="路径_1545" data-name="路径 1545" d="M5.04-29.92,24.16-31.6l.32,3.76-8.6.76L13.6-22.76l8.96,1.44,1.52,2.52L19.72-7.28l2.12,1.44L19.68-2.76,17.6-4.16,7.48,2.88,5.28-.16l9-6.32L9.12-10.04l2.16-3.12,5.24,3.64,3.2-8.44-8-1.24L6.88-10.04l-3.36-1.8L11.4-26.68l-6.04.52ZM26.52-.56,32.72-3l-.6-28.6,3.8-.04L36.48-1.8,35.32,0,27.88,2.88ZM25.32-24l3.76-.12.48,16.88-3.72.12Zm21.92-5.28,10.96-.44.04-1.88,3.8.08L62-29.88l12-.52.16,3.76-12.2.52-.04,2.64,9.84-.4.12,3.8-10,.4v1.76l13.72-.72.2,3.8-14.52.76-.04.08,5.32,4.48,5.4-3.32,1.92,3.2L69.56-6.96l7.4,6.24L74.48,2.12,58.32-11.56,55.24-9.2l.24,7.48,4.96-2.12L61.96-.4,54.44,2.8l-2.6-1.64L51.52-6.4,45.36-1.68l-2.32-3,11.92-9.08-9.8.52-.2-3.8,13.12-.68.04-1.8-7.52.28-.16-3.8,7.72-.28v-2.64l-10.76.44Zm69.28,17.8-3.76-.32,1.2-14.56L100.8-23.88l.92,13.48-3.8.2L96.68-29.6l3.72-.24.12,2.2,15.16-2.84,2.24,2ZM92.08-14.32,97-11,94.92-7.88l-3-2L91.8,1.84,88,1.8l.12-10.48L84.68-3.8,81.56-6l6.64-9.32v-.12h.08L91-19.24l-8-.52.2-3.72,11.4.68L96-19.84Zm8.2,16.96L96.8,1.16l10.6-24.6,3.48,1.48Zm9.6-1.32L107.72-.4l-.44-8.8L111-9.4l.36,6.72,6.44-1.08.6,3.72ZM89.92-23.96l-4.36-5L88.4-31.4l4.36,4.96Zm61.88,27-25.16-.6L124.8.64l-1.32-29.6,1.8-2,29.36-.88,1.88,2.08L153.68,1.32ZM128.48-1.24l16.64.36L131.84-3.36l.68-3.72,13.88,2.6-.72,3.6,4.4.08L152.6-28l-16.08.52,1.52,1.32-1.2,1.36,12.84,3.04.36,3.56-6.32,2.84,6.56,2.72L148.84-9.2l-9.8-4.04L135.4-11.6l10.72,2.12-.68,3.68L134.48-7.96l.72-3.56-5.68,2.56L128-12.4l6.24-2.8-1.52-.64,1.4-3.52,4.84,2.04,4.48-2.04-9.4-2.28v-.08l-3,3.36-2.8-2.48,5.88-6.56-6.8.2Z" transform="translate(-3.52 31.84)" fill="#fff"/></svg>',
					nameOn: '<svg xmlns="http://www.w3.org/2000/svg" width="153" height="34.88" viewBox="0 0 153 34.88"><path id="路径_1546" data-name="路径 1546" d="M5.04-29.92,24.16-31.6l.32,3.76-8.6.76L13.6-22.76l8.96,1.44,1.52,2.52L19.72-7.28l2.12,1.44L19.68-2.76,17.6-4.16,7.48,2.88,5.28-.16l9-6.32L9.12-10.04l2.16-3.12,5.24,3.64,3.2-8.44-8-1.24L6.88-10.04l-3.36-1.8L11.4-26.68l-6.04.52ZM26.52-.56,32.72-3l-.6-28.6,3.8-.04L36.48-1.8,35.32,0,27.88,2.88ZM25.32-24l3.76-.12.48,16.88-3.72.12Zm21.92-5.28,10.96-.44.04-1.88,3.8.08L62-29.88l12-.52.16,3.76-12.2.52-.04,2.64,9.84-.4.12,3.8-10,.4v1.76l13.72-.72.2,3.8-14.52.76-.04.08,5.32,4.48,5.4-3.32,1.92,3.2L69.56-6.96l7.4,6.24L74.48,2.12,58.32-11.56,55.24-9.2l.24,7.48,4.96-2.12L61.96-.4,54.44,2.8l-2.6-1.64L51.52-6.4,45.36-1.68l-2.32-3,11.92-9.08-9.8.52-.2-3.8,13.12-.68.04-1.8-7.52.28-.16-3.8,7.72-.28v-2.64l-10.76.44Zm69.28,17.8-3.76-.32,1.2-14.56L100.8-23.88l.92,13.48-3.8.2L96.68-29.6l3.72-.24.12,2.2,15.16-2.84,2.24,2ZM92.08-14.32,97-11,94.92-7.88l-3-2L91.8,1.84,88,1.8l.12-10.48L84.68-3.8,81.56-6l6.64-9.32v-.12h.08L91-19.24l-8-.52.2-3.72,11.4.68L96-19.84Zm8.2,16.96L96.8,1.16l10.6-24.6,3.48,1.48Zm9.6-1.32L107.72-.4l-.44-8.8L111-9.4l.36,6.72,6.44-1.08.6,3.72ZM89.92-23.96l-4.36-5L88.4-31.4l4.36,4.96Zm61.88,27-25.16-.6L124.8.64l-1.32-29.6,1.8-2,29.36-.88,1.88,2.08L153.68,1.32ZM128.48-1.24l16.64.36L131.84-3.36l.68-3.72,13.88,2.6-.72,3.6,4.4.08L152.6-28l-16.08.52,1.52,1.32-1.2,1.36,12.84,3.04.36,3.56-6.32,2.84,6.56,2.72L148.84-9.2l-9.8-4.04L135.4-11.6l10.72,2.12-.68,3.68L134.48-7.96l.72-3.56-5.68,2.56L128-12.4l6.24-2.8-1.52-.64,1.4-3.52,4.84,2.04,4.48-2.04-9.4-2.28v-.08l-3,3.36-2.8-2.48,5.88-6.56-6.8.2Z" transform="translate(-3.52 31.84)" fill="#49fcfc"/></svg>'
				}
			],
			location: {
				client: {},
				position: {}
			},
			list: [],
			bodyOn: -1,
			iconOn: 0,
			listLeft: [0, 3, 4, 5, 7, 8, 9, 11],
			listRight: [1, 2, 6, 10],
			spotState: true, // 人体结构上黄色点
			listUp: true, // 左侧列表展开/隐藏
			refInfo: '<ul><li>赖克方. 慢性咳嗽[M]. 北京: 人民卫生出版社, 2019:217-382.</li></ul>'
		};
  },
	components: {
		Reference
	},
  created() {
		this.findInfo()
	},
	activated() {  
		if(!this.$route.meta.isUseCache){
			this.tabIdx = 0;
			this.bodyOn = -1;
			this.iconOn = 0;
			this.findInfo();
		}
		this.$route.meta.isUseCache = false;
	},
  mounted() {
		this.$wxShare._wxConfigJSSDK()

		// 访问记录
		this.$Common._record({type: 1, openid: this.$store.state.openid, module_id: 3, page: this.$route.path})
		// alert(window.screen.height)
	},
  methods: {
		/**
		 * 获取数据
		 */
		findInfo () {
			let that = this
			getDiagnosis({}).then(res => {
				// console.log(res)
				if (res.code === 200) {
					that.list = res.data.cause_diagnosis_list
				} else {
					Toast(res.msg)
				}
			})
		},

		onList () {
			this.list.forEach(function (value) {
				value.on = 0
			})
			this.bodyOn = -1
			this.spotState = true
			this.listUp = !this.listUp
		},

		/**
		 * 查看详情
		 */
		toDetail (type, detailId, id) {
			if (detailId) {
				this.$router.push('/disease?id=' + detailId + '&type=' + type)
			} else {
				this.$Common._record({type: type, openid: this.$store.state.openid, module_id: 3, category_id: id, page: this.$route.path})
			}
		},

		/**
		 * 人体/列表视图切换
		 */
		tabChange (i) {
			this.tabIdx = i
			let _type = i == 0 ? 1 : 2
			this.$Common._record({type: _type, openid: this.$store.state.openid, module_id: 3, page: this.$route.path})
		},

		/**
		 * 量表拖拽开始
		 */
		touchStart(refName, e) {
			let element = e.targetTouches[0]
			// 记录点击的坐标
			this.location.client = {
				x: element.clientX,
				y: element.clientY
			}
			// 记录需要移动的元素坐标
			this.location.position.left = this.$refs[refName].offsetLeft
			this.location.position.top = this.$refs[refName].offsetTop
		},

		/**
		 * 量表拖拽结束
		 */
		touchMove(refName, e) {
			let element = e.targetTouches[0]
			// 根据初始 client 位置计算移动距离(元素移动位置=元素初始位置+光标移动后的位置-光标点击时的初始位置)
			let x = this.location.position.left + (element.clientX - this.location.client.x)
			let y = this.location.position.top + (element.clientY - this.location.client.y)
			// 限制可移动距离，不超出可视区域
			x = x <= 0 ? 0 : x >= innerWidth - this.$refs[refName].offsetWidth ? innerWidth - this.$refs[refName].offsetWidth : x
			y = y <= 0 ? 0 : y >= innerHeight - this.$refs[refName].offsetHeight ? innerHeight - this.$refs[refName].offsetHeight : y
			// 移动当前元素
			this.$refs[refName].style.left = x + 'px'
			this.$refs[refName].style.top = y + 'px'
		},

		/**
		 * 列表视图-list切换
		 */
		onChange (i) {
			if (this.iconOn != i) {
				this.$Common._record({type: 2, openid: this.$store.state.openid, module_id: 3, category_id: this.list[i].id, page: this.$route.path})
				this.iconOn = i
			}
		},

		/**
		 * 选择查看二级分类
		 */
		onSelect (i) {
			// console.log(this.list[i])
			this.$Common._record({type: 1, openid: this.$store.state.openid, module_id: 3, category_id: this.list[i].id, page: this.$route.path})
			if (this.bodyOn !== i) {
				this.list.forEach(function (value) {
					value.on = 2
				})

				this.bodyOn = i
				this.list[i].on = 1
				this.spotState = false
			} else {
				this.list.forEach(function (value) {
					value.on = 0
				})
				this.bodyOn = -1
				this.spotState = true
			}
		},

		/**
		 * 评估工具
		 */
		toPath () {
			// this.$router.push(path)
			this.$router.push('/info?id=1')
		}
	}
};
</script>

<style scoped lang='scss'>
@import '../assets/css/icon.css';
.wrap{
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	overflow-x:hidden;
	overflow-y:scroll;
	background: #000;
	// background: url('../assets/img/bg.png') top center repeat-y;
	// background-size: 100% 100%;
}
.title{
	display: flex;
	align-items: center;
	position: fixed;
	top: 155px;

	svg{
		width: 154px;
		margin-left: 62px;
	}

	img{
		width: 30px;
		margin-left: 7px;
	}
}
.tip{
	font-size: 24px;
	position: fixed;
	top: 72px;
	right: 46px;

	>div{
		width: 154px;
	}
	.tip-1,.tip-2,.tip-3{
		width: 100%;
		display: flex;
		align-items: center;
		span{
			width: 20px;
			height: 20px;
			border-radius: 4px;
			margin-right: 6px;
		}
	}
	.tip-1{
		color: var(--color-1);
		span{
			background: var(--color-1);
		}
	}
	.tip-2{
		color: var(--color-2);
		span{
			background: var(--color-2);
		}
	}
	.tip-3{
		color: var(--color-3);
		span{
			background: var(--color-3);
		}
	}
}
.content{
	// margin-top: 120px;
	margin-top: 240px;
	.body{
		position: relative;
		.bg{
			width: 499px;
			height: 902px;
			margin-left: 250px;
		}
		.list{
			font-size: 26px;
			height: 0;
			position: absolute;
			top: -12px;
			left: 60px;
			transition: all 0.5s linear;
			overflow: hidden;

			&.on{
				height: 100%;
			}
			>li{
				margin-top: 34px;

				&:first-child{
					margin-top: 0;
				}
				>div{
					display: flex;
					align-items: center;
					.name{
						display: flex;
						align-items: center;

						&:before{
							content: '';
							width: 18px;
							height: 18px;
							background: url('../assets/img/diacrisis/spot-white.png') top center no-repeat;
							background-size: 100% 100%;
							margin-right: 3px;
						}
					}

					.drop{
						width: 19px;
						height: 11px;
						margin-left: 10px;
						background: url('../assets/img/drop.png') top center no-repeat;
						background-size: 100% 100%;
					}

					.circle{
						width: 20px;
						height: 20px;
						border: 5px solid #FFFFFF;
						border-radius: 50%;
						box-sizing: border-box;

						&.circle1{
							margin-left: 15px;
						}

						&.circle2{
							margin-right: 12px;
						}
					}

				}

				&.on{
					>div{
						.name{
							color: #49FCFC;
							&:before{
								background: url('../assets/img/diacrisis/spot-blue.png') top center no-repeat;
								background-size: 100% 100%;
							}
						}
					}
				}

				&.off{
					opacity: 0;
					pointer-events: none;
				}
			}
		}
		.sub-list{
			width: 66%;
			height: 100%;
			position: absolute;
			right: 0;
			top: 0;
			>li{
				position: absolute;
				.sub-list-spot{
					width: 18px;
					height: 18px;
				}
			}

			.sub-list-wrap{
				width: 310px;
				height: 0;
				position: absolute;
				overflow-y: scroll;
				z-index: 1;
				font-size: 24px;
				line-height: 48px;
				transition: all 0.3s linear;

				&.on{
					height: 357px;
				}

				.sub-list-name{
					margin: 18px 22px 0;
					display: flex;
					align-items: center;
				}

				ul{
					height: 270px;
					overflow-y: scroll;
					li{
						margin: 0 22px;
						font-weight: bold;
						border-bottom: 1px solid rgba(255, 255, 255, 0.43);
						// display: flex;
						// flex-wrap: wrap;
						// align-items: center;

						>div{
							position: relative;
							// width: 100%;
							&.on:before{
								content: '';
								width: 40px;
								height: 40px;
								background: url('../assets/img/next.png') top center no-repeat;
								background-size: 100% 100%;
								position: absolute;
								right: 0;
								bottom: 0;
							}
						}

						// img{
						// 	width: 40px;
						// 	height: 40px;
						// }

						&:last-child{
							border-bottom: none;
						}

						&.li_1{
							color: var(--color-1);
						}

						&.li_2{
							color: var(--color-2);
						}

						&.li_3{
							color: var(--color-3);
						}
					}
				}
			}

			.list_10{
				.sub-list-wrap.on{
					height: 200px;
				}
				ul{
					height: 100px;
				}
			}

			.list_11{
				.sub-list-wrap{
					&.on{
						height: 250px;
					}
					ul{
						height: 180px;
					}
				}
			}

			.list_0,.list_3,.list_5,.list_7,.list_10,.list_10,.list_11{
				.sub-list-wrap{
					background: url('../assets/img/diacrisis/Q_frame_2.png') top center no-repeat;
					background-size: 100% 100%;
					right: -25px;
					top: -35px;

					.sub-list-name{
						justify-content: flex-end;
					}
					.sub-list-name:after{
						content: '';
						width: 18px;
						height: 18px;
						background: url('../assets/img/diacrisis/spot-white.png') top center no-repeat;
						background-size: 100% 100%;
						margin-left: 3px;
					}
				}
			}

			.list_1,.list_2,.list_4,.list_6,.list_8,.list_9{
				.sub-list-wrap{
					background: url('../assets/img/diacrisis/Q_frame_1.png') top center no-repeat;
					background-size: 100% 100%;
					left: -25px;
					top: -35px;

					.sub-list-name:before{
						content: '';
						width: 18px;
						height: 18px;
						background: url('../assets/img/diacrisis/spot-white.png') top center no-repeat;
						background-size: 100% 100%;
						margin-right: 3px;
					}
				}
			}

			.list_3{
				.sub-list-wrap{
					width: 260px;
				}
			}
		}
	}
	
	.view{
		position: relative;
		margin-top: -100px;
		.bg{
			// width: 66%;
			// margin-left: 34%;
			width: 499px;
			height: 902px;
			margin-left: 250px;
			opacity: 0.38;
		}
		.view-wrap{
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			display: flex;

			.left{
				width:20%;
				height: 106%;
				// height: 96%;
				position: relative;
				overflow-y: scroll;

				div{
					width: 70px;
					height: 70px;
					// width: 60px;
					// height: 60px;
					display: flex;
					align-items: center;
					justify-content: center;
					margin-left: 50px;
					// margin-left: 55px;
					margin-top: 10px;
					background: transparent;

					&.on{
						background: url('../assets/img/list_frame.png') top center no-repeat;
						background-size: 99% 99%;
					}

					&:first-child{
						margin-top: 0;
					}
				}

				&::after{
					content: '';
					width: 4px;
					height: 100%;
					background: url('../assets/img/icon_line.png') top center no-repeat;
					background-size: 100% 100%;
					position: absolute;
					top: 0;
					right: 0;
				}
			}
			.right{
				width: calc(81% - 24px);
				height: 96%;
				font-size: 26px;
				// font-size: 24px;
				margin-left: 24px;
				color: #191919;
				position: relative;

				.name{
					font-size: 40px;
					line-height: 40px;
					color: #FFFFFF;
					font-weight: bold;
					position: absolute;
					top: 0;
					left: 0;
				}

				ul{
					height: 92%;
					overflow-y: scroll;
					margin-top: 10%;
					li{
						width: calc(85% - 36px);
						// border-radius: 29px;
						border-top-left-radius: 50px;
						border-bottom-left-radius: 50px;
						line-height: 40px;
						margin-top: 17px;
						padding: 5px 18px;
						display: flex;
						align-items: center;

						img{
							width: 40px;
						}

						&.li_1{
							background: linear-gradient(55deg, #FF9CAF 40%, rgba(255, 156, 175, 0) 100%);
						}

						&.li_2{
							background: linear-gradient(55deg, #D1BF9E 40%, rgba(209, 191, 158, 0.12) 85%, rgba(209, 191, 158, 0) 100%);
						}

						&.li_3{
							background: linear-gradient(88deg, #A5ECDC 40%, rgba(165, 236, 220, 0) 100%);
						}
					}
				}
			}
		}
	}
}
.tab{
	width: 70%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: fixed;
	left: 15%;
	bottom: 80px;

	.tab-list{
		display: flex;
		align-items: center;
		position: relative;
		font-size: 40px;
		line-height: 48px;
		padding-bottom: 14px;
		color: #fff;
		/deep/ svg{
			width: 160px;
		}
		img{
			width: 48px;
			flex: 1;
			margin-right: 12px;
		}
		&:after{
			content: '';
			width: 100%;
			height: 5px;
			position: absolute;
			bottom: 0;
			left: 0;
			background: url('../assets/img/home_line.png') top center no-repeat;
			background-size: 100% 100%;
		}

		&.on{
			color: #49FCFC!important;
			&:after{
				background: url('../assets/img/home_line_1.png') top center no-repeat;
				background-size: 100% 100%;
			}
		}
	}
}
.assess{
	width: 88%;
	height: 80px;
	font-size: 50px;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(255, 255, 255, 0.1);
	border: 3px solid #FFFFFF;
	border-radius: 18px;
	box-sizing: border-box;
	margin: auto;
	margin-top: 35px;
	img{
		height: 47px;
	}
}
.treat{
	font-size: 32px;
	text-align: center;
	margin-top: 30px;
}
.gauge{
	width: 84px;
	position: fixed;
	bottom: 140px;
	right: 30px;
	pointer-events: initial;
	z-index: 99;
}
.copyright{
	position: fixed;
	left: 0;
	bottom: 10px;
	z-index: 0;
}

@media (device-height:568px) and (-webkit-min-device-pixel-ratio:2){/* 兼容iphone5 */
	.title{
		top: 110px;
	}
	.content{
		margin-top: 120px;
		.view{
			margin-top: 0;
			.bg{
				width: 430px;
				height: 777px;
			}
		}
	}
	.list{
		top: 20px!important;
		>li{
			margin-top: 25px!important;
		}
	}
}
@media (device-height:667px) and (-webkit-min-device-pixel-ratio:2){/*兼容iphone6，iphone7，iphone8s ：*/
	.title{
		top: 110px;
	}
	.content{
		margin-top: 120px;
		.view{
			margin-top: 0;
			.bg{
				width: 430px;
				height: 777px;
			}
		}
	}
	.list{
		top: 20px!important;
		>li{
			margin-top: 25px!important;
		}
	}
}
@media (device-height:736px) and (-webkit-min-device-pixel-ratio:2){/*兼容iphone6 Plus,iphone7 Plus,iphone8 Plus：*/
	.title{
		top: 110px;
	}
	.content{
		margin-top: 120px;
		.view{
			margin-top: 0;
		}
	}
	.list{
		top: 20px!important;
		>li{
			margin-top: 25px!important;
		}
	}
}
</style>
